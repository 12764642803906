import { createWebHistory, createRouter } from "vue-router";
import Home from "../pages/Home/Home.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: Home,
      name: "Homepage",
    },
    {
      path: "/home",
      component: Home,
    },
    {
      path: "/about",
      // component: About,
      name: "About",
      component: () => import("../pages/About/About.vue"),
    },
    {
      path: "/services",
      // component: Services,
      name: "Services",
      component: () => import("../pages/Services/Services.vue"),
    },
    {
      path: "/team",
      // component: Team,
      name: "team",
      component: () => import("../pages/Team/Team.vue"),
    },
    {
      path: "/:catchAll(.*)",
      // component: ErrorPage,
      component: () => import("../pages/404/404.vue"),
    },
    {
      path: "/blog",
      // component: Blog,
      name: "Blog",
      component: () => import("../pages/Blog/Blog.vue"),
    },
    {
      // Route with a dynamic parameter for the blog ID
      path: "/blog/:id",
      name: "blog-details",
      props: true,
      // component: BlogDetails,
      component: () => import("../pages/BlogDetails/BlogDetails.vue"),
    },
    {
      // Route with a dynamic parameter for the blog ID
      path: "/careerDetails/:id",
      name: "career-details",
      props: true,
      // component: CareerDetails,
      component: () => import("../pages/CareersDetails/CareersDetails.vue"),
    },
    {
      path: "/contact",
      // component: Contact,
      name: "Contact",
      component: () => import("../pages/Contact/Contact.vue"),
    },
    {
      path: "/contact-2",
      // component: Contact,
      name: "Contact-2",
      component: () => import("../pages/Contact/Contact-2.vue"),
    },
    {
      path: "/project-idea",
      // component: Contact,
      name: "Project-Contact",
      component: () => import("../pages/Contact/Project-Contact.vue"),
    },
    {
      path: "/application/:id",
      props: true,
      // component: Application,
      name: "Application",
      component: () => import("../pages/Application/Application.vue"),
    },
    {
      path: "/careers",
      // component: Careers,
      name: "Careers",
      component: () => import("../pages/Careers/Careers.vue"),
    },
    {
      path: "/why-aipath",
      // component: WhyAiPath,
      name: "why-aipath",
      component: () => import("../pages/Why AiPath/WhyAiPath.vue"),
    },
    {
      path: "/with-aipath",
      // component: WhyAiPath,
      name: "with-aipath",
      component: () => import("../pages/With AiPath/WithAiPath.vue"),
    },
    {
      path: "/gdpr",
      // component: Gdpr,
      name: "Gdpr",
      component: () => import("../pages/Gdpr/Gdpr.vue"),
    },
    {
      path: "/tos",
      // component: Gdpr,
      name: "tos",
      component: () => import("../pages/Tos/Tos.vue"),
    },
    {
      path: "/api-dev",
      // component: ApiDev,
      name: "Api Development",
      component: () => import("../pages/Development/ApiDevelopment.vue"),
    },
    {
      path: "/custom-apps",
      // component: CustomDev,
      name: "Custom Desktop Apps",
      component: () =>
        import("../pages/Development/CustomDesktopApplication.vue"),
    },
    {
      path: "/ecommerce",
      // component: Ecommerce,
      name: "Ecommerce",
      component: () => import("../pages/Development/ECommerceSolutions.vue"),
    },
    {
      path: "/mobile-apps",
      // component: MobileApps,
      name: "Mobile Apps",
      component: () => import("../pages/Development/MobileApplications.vue"),
    },
    {
      path: "/web-apps",
      // component: WebApps,
      name: "Web Applications",
      component: () => import("../pages/Development/WebApplications.vue"),
    },
    {
      path: "/brand-development",
      // component: BrandDevelopment,
      name: "Brand Development",
      component: () => import("../pages/ForStartUps/BrandDevelopment.vue"),
    },
    {
      path: "/business-strategy",
      // component: BusinessStrategy,
      name: "Business Strategy",
      component: () => import("../pages/ForStartUps/BusinessStrategy.vue"),
    },
    {
      path: "/cloud-solutions",
      // component: CloudSolutions,
      name: "Cloud Solutions",
      component: () => import("../pages/ForStartUps/CloudSolutions.vue"),
    },
    {
      path: "/design-uiux",
      // component: DesignUIUX,
      name: "Design UI/UX",
      component: () => import("../pages/ForStartUps/DesignUIUX.vue"),
    },
    {
      path: "/digital-consulting",
      // component: DigitalConsulting,
      name: "Digital Consulting",
      component: () => import("../pages/ForStartUps/DigitalConsulting.vue"),
    },
    {
      path: "/digital-marketing",
      // component: DigitalMarketing,
      name: "Digital Marketing",
      component: () => import("../pages/ForStartUps/DigitalMarketing.vue"),
    },
    {
      path: "/website-development",
      // component: WebsiteDevelopment,
      name: "Website Development",
      component: () => import("../pages/ForStartUps/WebsiteDevelopment.vue"),
    },
    {
      path: "/machine-learning-solutions",
      // component: MachineLearningSolutions,
      name: "Machine Learning Solutions",
      component: () =>
        import(
          "../pages/MachineLearningSolutions/MachineLearningSolutions.vue"
        ),
    },
    // {
    //   path: "/edu",
    //   component: Edu,
    //   name: 'Education',
    //   component: () => import('')
    // },
    // {
    //   path: "/careerDetails",
    //   // component: CareerDetails,
    //   name: 'Career Details',
    //   component: () => import('../pages/CareersDetails/CareersDetails.vue')
    // },
  ],
});

router.beforeEach((to, from, next) => {
  next();
  window.scrollTo(0, 0);
});

export default router;
